<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<div class="homepageM">
			
			<!-- 轮播图 -->
			<div class="slideshowHomeM">
				<van-swipe
				  ref="swipe"
				  style="height: 420px"
				  :autoplay="autoplay"
				  indicator-color="white"
				  @change="onChange"
				>
				  <van-swipe-item v-for="(item, index) in slideshowImg" :key="index"
				     @touchstart="onTouchStart"
					 @touchmove="onTouchMove"
					 @touchend="onTouchEnd(item)">
				    <img v-lazy="item.imgUrl" v-show="item.type =='img'" @click="link(item)" class="slideshowHomeM-img"/>
				    <video
				      :id="'video' + item.id"
				      controls="controls"
				      v-show="item.type =='video'"
				      loop="loop"
					   :muted="true"
					  class="slideshowHomeM-img">
				      <source :src="item.imgUrl" />
				    </video>
				  </van-swipe-item>
				</van-swipe>
			</div>
			
			<!-- 画布 -->
			<div class="coverageHomeM">
				<el-image class="coverageHomeM-img" src="/home/coverage.png"></el-image>
				<el-image class="coverageHomeM-newsimg" src="/index/xinwen.png"></el-image>
			</div>
			
			<div class="newsHomeM">
				<!-- 新闻-信息 -->
				<div class="NewsMessageHomeMinformation">
					<div class="NewsMessageHomeMinformationData">
						<div class="NewsMessageHomeMinformationLeft"></div>
						<div class="NewsMessageHomeMinformationCenter">
							<p class="NewsMessageHomeMtitle">News</p>
						</div>
						<div class="NewsMessageHomeMinformationLeft"></div>
					</div>
					<p class="newsHomeM-message">
						{{newsmessage}}
					</p>
					<el-image class="newsHomeM-chainimg" src="/home/chain.png"></el-image>
				</div>
				<!-- 新闻-数据 -->
				<div class="newsHomeM-databox">
					<div v-for="(item,id) in ListData" :key="id" class="newsHomeM-dataone" @click="newsDetails(item)">
						<img :src="newsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="newsMdata-img"></img>
						<el-image class="newsMdata-coverageimg" src="/home/newCoverage.png"></el-image>
						<el-image class="newsHomeMdata-logoimg" src="/home/logo.png"></el-image>
						<div class="newsMdata-titleMessage">
							<p class="newsMdata-title">{{item.title}}</p>
							<p class="newsMdata-date">
								<span>{{item.month}}</span>
								<span>{{item.time}},</span>
								<span>{{item.year}}</span>
							</p>
						</div>
					</div>
				</div>
				<el-row class="newsHomeM-buttonbox">
					<el-col :span="24">
						<el-button class="newsHomeM-button" round @click="load">Learn More</el-button>
					</el-col>
				</el-row>
			</div>
			
			<!-- 剧院 -->
			<moveTheaterdata :toType="type"></moveTheaterdata>
			
			<!-- 画布 -->
			<div class="coverageHomeM">
				<el-image class="coverageHomeM-img" src="/home/coverage.png"></el-image>
				<el-image class="coverageHomeM-newsimg" src="/index/evenas.png"></el-image>
			</div>
			
			<!-- 特色事件 -->
			<moveFeatureddata></moveFeatureddata>
			
			<!-- AD Banne -->
			<div class="ADBanneHomeM">
				<el-carousel height="170px">
					<el-carousel-item v-for="(item, index) in bottomSlideshowImg" :key="index">
						<img :src="bannerNotLoadImage" :onload="onLoadImg(item)" class="bottomimgHomeM" @click="linkBottom(item)">
					</el-carousel-item>
				</el-carousel>
			</div>
			
			<!-- 画布 -->
			<div class="coverageHomeM">
				<el-image class="coverageHomeM-img" src="/home/coverage.png"></el-image>
				<el-image class="coverageHomeM-newsimg" src="/index/notice.png"></el-image>
			</div>
			
			<!-- 公告 -->
			<moveNoticesdata></moveNoticesdata>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>

import { selectPictureListById , selectContentListById } from '@/api/index.js'
	
// 头部组件
import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
// 底部导航
import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
// 菜单导航
import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
// 新闻数据
import moveTheaterdata from '@/components/move/moveTheaterdata.vue'
// 特色事件
import moveFeatureddata from '@/components/move/moveFeatureddata.vue'
// 公告
import moveNoticesdata from '@/components/move/moveNoticesdata.vue'
// 轮播图组件
import slideshowBox from '@/components/slideshowBox.vue'

export default 
{
	data()
	{
		return{
			// 导航值
			navigationvalue:false,
			
			type:'M',
			
			// 首页轮播图
			slideshowImg:[],
			autoplay: 3000,
			videoTime:'',
			isautoplay:false,
			
			// 底部轮播图
			bottomSlideshowImg:[],
			
			newList:[],
			
			list:[],
			bottomList:[],
			
			// 新闻新数组
			newsList:{},
			
			clickIndex:0,
			clickFlag:false,
			
			
			newsNotLoadImage: "/Mimage/454_520.jpg", //新闻图片未加载出来
			bannerNotLoadImage: "/Mimage/banner.jpg", //banner图片未加载出来
			
			newsmessage:'What’s Going On in Theatre Network, Productions, Ticketing Platform, Outreach & Education, International Collaboration, and Talents Up-bringing.',
		}
	},
	components:
	{
		moveHeadLogo,
		moveBottomNavigation,
		moveMeauDialog,
		moveTheaterdata,
		moveFeatureddata,
		moveNoticesdata,
		slideshowBox
	},
	computed:
	{
		// 新闻
		ListData()
		{
			let data=[];
			if(this.newList.length>3)
			{
				data=this.newList.slice(0,3)
				return data;
			}
			else
			{
				data=this.newList;
				return data;
			}
		},
	},
	created()
	{
		this.imgList()
		this.bottomImgList();
		this.getNewsList();
	},
	methods:
	{
		// 首页轮播图
		imgList()
		{
			selectPictureListById({chnanelType:2,picturetype:1})
			.then((response)=>
			{
				this.list=response.data.data;
				let videoList = []
				response.data.data.map((v, index)=>
				{
					console.log(v)
					if(v.pictureUrl!='' && v.pictureUrl!=null)
					{
						let data={id: index, 'imgUrl':v.pictureUrl + '?x-oss-process=image/resize,h_420,limit_0','type':'img','pictureLink':true,'url':v.url}
						this.slideshowImg.push(data);
					}
					if(v.videoUrl!=null && v.videoUrl!='')
					{
						let data={id: index, 'imgUrl':v.videoUrl,'type':'video','pictureLink':false}
						this.slideshowImg.push(data);
						videoList.push(data);
					}
				})
				
				this.$nextTick(() => 
				{
					videoList.forEach(item => {
						let video = document.getElementById(`video${item.id}`)
						video.addEventListener('playing', () => {
							this.autoplay =(video.duration-video.currentTime) * 1000
						})
						video.addEventListener('pause', () => {
							this.autoplay = 1000
							// swipe.swipeTo(id + 1)
						})
					})
				})
			})
		},
		// 底部轮播图
		bottomImgList()
		{
			selectPictureListById({chnanelType:2,picturetype:2})
			.then((response)=>
			{
				this.bottomList=response.data.data;
				response.data.data.map((v)=>
				{
					this.bottomSlideshowImg.push(v.pictureUrl);
				})
			})
		},
		// 头部传来的数据
		navigation(v)
		{
			this.navigationvalue=v;
			console.log(this.navigationvalue,'头部传递菜单导航')
		},
		// 遮罩层传回来的数据
		menu(v)
		{
			this.navigationvalue=v;
		},
		
		// 跳转连接
		link(item)
		{
			// if(item.pictureLink==true)
			// {
			// 	this.list.map((v)=>
			// 	{
			// 		if(v.pictureUrl==item.imgUrl)
			// 		{
			// 			if(v.url!=null && v.url!='')
			// 			{
			// 				window.location.href=v.url;
			// 			}
			// 		}
			// 	})
			// }
		},
		// 底部跳转
		linkBottom(item)
		{
			this.bottomList.map((v)=>
			{
				if(v.pictureUrl==item)
				{
					window.location.href=v.url;
				}
			})
		},
		
		// 获取NEWS新闻数据
		getNewsList()
		{
			selectContentListById(
			{
			  chnanelType: 2,
			  isIndex: 1,
			  moduleType: 'MODULE_WHAT'
			})
			.then((response)=>
			{
				this.newList=response.data.data;
				this.newList.map((v)=>
				{
					this.time(v)
				})
			})
		},
		
		// 新闻
		load()
		{
			this.$router.push('/moveNews')
		},
		
		// 新闻详情
		newsDetails(item)
		{
			localStorage.setItem('Details',JSON.stringify(item));
			this.$router.push(
			{
				path:'/moveNoticesDetails',
				query:
				{
					item:item,
					headTitle:'WHAT`S ON',
					headImg:'/index/list.png'
				}
			})
		},
		// 监听轮播图改变
		onChange(index) 
		{
			// 如果是视频,就等播放完再进行下一个
			if (this.slideshowImg[index].type == 'video') 
			{
				console.log(this.isautoplay,222)
				this.videoTime = document.getElementById(`video${index}`);
				this.videoTime.addEventListener('pause', function(){
				          console.log('isPlaying')
				    })
				this.videoTime.currentTime=0;
				if(this.videoTime.currentTime==0)
				{
					this.autoplay = 5000;
					this.isautoplay=false;
				}
				else
				{
					this.autoplay =(this.videoTime.duration-this.videoTime.currentTime) * 1000;
					this.isautoplay=true;
				}
			}
			else 
			{
				// 如果是图片就暂停三秒
				this.autoplay = 3000;
			}
		},
		
		// 用于判断点击还是滑过
		onTouchStart(e)
		{
			this.clickIndex=0;
			this.clickFlag=false;
		},
		onTouchMove(e)
		{
			this.clickFlag=true;
			this.clickIndex=this.clickIndex+1;
			if(this.clickIndex>2)
			{
				this.clickFlag=true;
			}
		},
		onTouchEnd(position)
		{
			if(this.clickFlag)
			{
				console.log('滑动')
			}
			else
			{
				console.log('点击')
				if(position.url!=null)
				{
					window.location.href=position.url;
				}
			}
		},
		// 图片加载完成时触发
		onLoadImg(imgSrc) 
		{
			return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
		},
		// 时间
		time(v)
		{
			v.pulish=v.publish.split('-')
			if(v.pulish[1]=='01')
			{
				v.pulish[1]='JAN'
			}
			else if(v.pulish[1]=='02')
			{
				v.pulish[1]='FEB'
			}
			else if(v.pulish[1]=='03')
			{
				v.pulish[1]='MAR'
			}
			else if(v.pulish[1]=='04')
			{
				v.pulish[1]='APR'
			}
			else if(v.pulish[1]=='05')
			{
				v.pulish[1]='MAY'
			}
			else if(v.pulish[1]=='06')
			{
				v.pulish[1]='JUN'
			}
			else if(v.pulish[1]=='07')
			{
				v.pulish[1]='JUL'
			}
			else if(v.pulish[1]=='08')
			{
				v.pulish[1]='AUG'
			}
			else if(v.pulish[1]=='09')
			{
				v.pulish[1]='SEP'
			}
			else if(v.pulish[1]=='10')
			{
				v.pulish[1]='OCT'
			}
			else if(v.pulish[1]=='11')
			{
				v.pulish[1]='NOV'
			}
			else if(v.pulish[1]=='12')
			{
				v.pulish[1]='DEC'
			}
			v.year=v.pulish[0];
			v.month=v.pulish[1];
			v.time=v.pulish[2];
		},
	}
}
</script>

<style>
	@import '../../assets/MoveNewsBox.css';
	.homepageM{
		width: 100%;
		margin-top: 20px;
	}
	.slideshowHomeM{
		width: 100%;
		height: 420px;
		position: relative;
	}
	.slideshowHomeM-img{
		width: 100%;
		height: 420px;
	}
	.newsHomeM-databox{
		margin-top: 50px;
		white-space: nowrap;
		overflow-y: scroll;
	}
	/* 去掉滚动条 */
	::-webkit-scrollbar{
		display: none;
	}
	.newsHomeM-button{
		width: 600px;
		height: 80px;
		background: #FDD000;
		border-radius: 50px !important;
		margin-top: 29px;
		font-size: 30px;
		border: none;
		color: #505050;
	}
	.newsHomeM-chainimg{
		width: 128px;
		height: 13px;
		margin-top: 22px;
	}
	.coverageHomeM{
		width: 100%;
		position: relative;
	}
	.coverageHomeM .coverageHomeM-img{
		z-index: 9;
		position: absolute;
		width: 100%;
		height: 40px;
		left: 0px;
		bottom: -1px;
	}
	.coverageHomeM .coverageHomeM-newsimg{
		width: 42px;
		height: 42px;
		position: absolute;
		left: 354px;
		bottom: -20px;
		z-index: 99;
	}
	.ADBanneHomeM{
		width: 750px;
		height: 160px;
		margin-top: 10px;
	}
	.bottomimgHomeM{
		width: 750px;
		height: 160px;
	}
	.newsHomeM{
		width: 100%;
		margin-top: 50px;
	}
	.newsHomeM-information{
		width: 92%;
		border: solid 3px black;
		position: relative;
		padding-top: 30px;
		line-height: 35px;
		margin-left: 3%;
	}
	.newsHomeM-message{
		width: 90%;
		height: 70px;
		margin: 0% 5%;
		text-align: center;
		word-wrap: break-word;
		white-space: pre-wrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 20px;
		color: #505050;
		margin-top: 33px;
	}
	.el-carousel__button{
		width: 5px !important;
		height: 5px !important;
		border-radius: 100%;
	}
	.el-carousel__indicators--outside button{
		width: 5px !important;
		height: 5px !important;
		border-radius: 100%;
	}
	
	.NewsMessageHomeMinformationCenter{
		flex: 1;
		position: relative;
	}
	.NewsMessageHomeMtitle{
		width: 100%;
		height: 36px;
		position: absolute;
		top: -20px;
		font-size: 48px;
		color: #505050;
	}
	.NewsMessageHomeMinformationLeft{
		flex: 1;
		border-top: solid 1px black;
	}
	.NewsMessageHomeMinformationData{
		display: flex;
	}
	.NewsMessageHomeMinformation{
		width: 90%;
		margin: 39px 5% 0px;
		height: 180px;
		border: solid 1px black;
		line-height: 35px;
		border-top: none;
		color: #505050;
	}
	.ADBanneHomeM .el-carousel__indicators--horizontal{
		bottom: 3vw !important;
	}
	.ADBanneHomeM .el-carousel__arrow{
		top: 80px !important;
	}
	.ADBanneHomeM .el-carousel__arrow{
		width: 50px !important;
		height: 50px !important;
		background-color: rgba(31,45,61,0.7) !important;
	}
</style>