import { render, staticRenderFns } from "./moveNoticesdata.vue?vue&type=template&id=048a1496&scoped=true&"
import script from "./moveNoticesdata.vue?vue&type=script&lang=js&"
export * from "./moveNoticesdata.vue?vue&type=script&lang=js&"
import style0 from "./moveNoticesdata.vue?vue&type=style&index=0&id=048a1496&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "048a1496",
  null
  
)

export default component.exports