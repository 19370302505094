<template>
	<div class="theaterHomeSildeM">
		<!-- 剧院-信息 -->
		<div class="theaterHomeSildeM-information">
			<div class="theaterHomeSildeM-informationData">
				<div class="theaterHomeSildeM-informationLeft"></div>
				<div class="theaterHomeSildeM-informationCenter">
					<p class="theaterHomeSildeM-title">Theatres</p>
				</div>
				<div class="theaterHomeSildeM-informationLeft"></div>
			</div>
			
			<p class="theaterHomeSildeM-message">
				{{message}}
			</p>
			<el-image class="theaterHomeSildeM-chainimg" src="/home/chain.png"></el-image>
		</div>
		
		<!-- 剧院-轮播图 -->
		<div class="theaterHomeSildeM-slideshow">
			<el-carousel :interval="4000" type="card" @change="silde" class="theaterHomeSildeM-slideshowBox">
				<el-carousel-item v-for="(item,id) in theaterSlideshowdata" :key="id" class="theaterHomeSildeM-sli">
					<div @click="jumpTheater(item)">
						<el-image class="theaterHomeSildeM-slideshowimg" :src="item.pictureUrl"></el-image>
					</div>
				</el-carousel-item>
			</el-carousel>
			<div  @click="jumpTheater(sildeData)" class="theaterHomeSildeM-list">
				<template v-if="!!sildeData">
				<p class="theaterHomeSildeM-shanghai-title">{{sildeData.theaterName}}</p>
				<p class="theaterHomeSildeM-shanghai-message" v-html="sildeData.bodyContent">{{sildeData.bodyContent}}</p>
			</template>
			</div>
		</div>
		<div class="theaterHomeSildeM-buttonbox">
			<button @click="theaterDetails" class="theaterHomeSildeM-button">Learn More</button>
		</div>
	</div>
</template>

<script>
import { selectPictureListById , selectTheaterContentById } from '@/api/index.js'
	export default
	{
		data()
		{
			return{
				sildeData:{},
				theaterSlideshowdata:[],
				message:'Pace-making Theatre Operator; China’s Largest Theatre Network with over 70 Theatres from over 22 Provinces under Direct Management.'
			}
		},
		props:
		{
			toType:String
		},
		created()
		{
			this.theatersList()
		},
		methods:
		{
			// 获取剧院数据
			theatersList()
			{
				selectTheaterContentById(
				{
				  chnanelType: 2
				})
				.then((response)=>
				{
					this.theaterSlideshowdata=response.data.data.splice(0,3);
					this.getSlideshowData()
				})
			},
			getSlideshowData()
			{
				this.sildeData=this.theaterSlideshowdata[0];
			},
			// 获取轮播图滚动数据
			silde(item)
			{
				this.sildeData=this.theaterSlideshowdata[item];
			},
			// 进入剧院页面
			theaterDetails()
			{
				if(this.toType=='PC')
				{
					this.$router.push('/theaters')
				}
				else
				{
					this.$router.push('/moveTheaters')
				}
			},
			// 进入详情
			jumpTheater(item)
			{
				this.$router.push(
				{
					path:'/moveTheaters',
					query:
					{
						item:item,
					}
				})
			}
		}
	}
</script>

<style>
	.theaterHomeSildeM{
		width: 100%;
		height: 910px;
		margin-top: 40px;
		background-image: url('/home/ImagesM.png');
		padding-top: 50px;
		position: relative;
		background-size: 100%;
	}
	.theaterHomeSildeM-information{
		width: 90%;
		border: solid 3px white;
		line-height: 35px;
		margin-left: 5%;
		border-top: none;
		color: white;
	}
	.theaterHomeSildeM-informationData{
		display: flex;
	}
	.theaterHomeSildeM-informationLeft{
		flex: 1;
		border-top: solid 2px white;
	}
	.theaterHomeSildeM-informationCenter{
		flex: 1;
		position: relative;
	}
	.theaterHomeSildeM-title{
		width: 100%;
		height: 30px;
		position: absolute;
		top: -15px;
		font-size: 6vw;
	}
	.theaterHomeSildeM-message{
		width: 80%;
		margin-left: 10%;
		text-align: center;
		margin-top: 49px;
		
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		word-wrap:break-word;
		white-space: pre-wrap;
	}
	.theaterHomeSildeM-chainimg{
		width: 100px;
		height: 10px;
		margin:22px 0px 21px;
	}
	.theaterHomeSildeM-slideshow{
		margin-top: 30px;
	}
	.theaterHomeSildeM-slideshowimg{
		width: 100%;
		height: 281px;
		border-radius: 30px;
	}
	.theaterHomeSildeM-list{
		color: white;
		width: 90%;
		height: 155px;
		margin-left: 5%;
		font-size: 14px;
	}
	.theaterHomeSildeM-shanghai-title{
		font-size: 18px;
		margin-bottom: 20px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 30px;
	}
	.theaterHomeSildeM-shanghai-message{
		width: 100%;
		height: 100px;
		white-space: normal;
		color: white;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		word-wrap:break-word;
		white-space: pre-wrap;
		font-size: 20px;
		line-height:25px;
	}
	.theaterHomeSildeM-shanghai-message p{
		color: white !important;
	}
	.theaterHomeSildeM-shanghai-message p span{
		color: white !important;
	}
	.theaterHomeSildeM-buttonbox{
		width: 100%;
		height: 100px;
		margin-top: 30px;
	}
	.theaterHomeSildeM-button{
		color: white !important;
		line-height: 10px;
		border: none;
		border-radius: 50px;
		background-color: transparent;
		width: 600px;
		height: 80px;
		border: 1px solid #FFFFFF;
		font-size: 30px;
		color: #FFFFFF;
	}
	.theaterHomeSildeM-slideshowBox{
		height: 350px;
	}
	.el-carousel__mask{
		height: 230px;
	}
	.theaterHomeSildeM-slideshow .el-icon-arrow-left{
		color: white;
		font-size: 25px;
	}
	.theaterHomeSildeM-slideshow .el-icon-arrow-right{
		color: white;
		font-size: 25px;
	}
	.theaterHomeSildeM-slideshow .el-carousel__arrow{
		width: 50px !important;
		height: 50px !important;
		background-color: rgba(31,45,61,0.7) !important;
	}
</style>