<template>
	<!-- 公告 -->
	<div class="notices">
		<el-row>
			<el-col :span="24" class="evenas-title">
				Notices
			</el-col>
		</el-row>
		<el-row class="notices-databox">
			<template v-if="!!noticesList">
				<p class="notices-data" v-html="noticesList.bodyContent">
					{{noticesList.bodyContent}}
				</p>
			</template>
		</el-row>
		<div class="notices-buttonbox">
			<el-button class="news-button" round @click="notices">Learn More</el-button>
		</div>
	</div>
</template>

<script>
	import { selectContentListById } from '@/api/index.js'
	export default
	{
		data()
		{
			return{
				isnotices:false,
				noticesList:{}
			}
		},
		created()
		{
			this.getNoticesList()
		},
		methods:
		{
			notices()
			{
				this.$router.push('/moveNotices')
			},
			// 获取公告数据
			getNoticesList()
			{
				selectContentListById(
				{
				  chnanelType: 2,
				  isIndex: 1,
				  moduleType: 'MODULE_BRESAKING'
				})
				.then((response)=>
				{
					this.noticesList=response.data.data[0];
				})
			},
		}
	}
</script>

<style scoped>
	.notices{
		width: 100%;
		margin: 40px 0px 100px;
		position: relative;
	}
	.notices-databox{
		margin-top: 20px;
		padding-bottom: 50px;
		background-color: #f5f5f5;
		border: solid 2px #E5E5E5;
		width: 690px;
		margin-left:30px;
		border-radius: 12px;
	}
	.notices-data{
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		text-align: left;
		word-wrap:break-word;
		white-space: pre-wrap;
		width: 593px;
		height: 120px;
		margin: 54px 48px 40px;
		font-size: 20px;
		color: #505050;
		line-height: 30px;
	}
	.notices-datashow{
		width: 90%;
		border-radius: 5px;
		line-height: 30px;
		padding: 5% 5% 0%;
	}
	.evenas-title{
		text-align: center;
		height: 36px;
		font-size: 48px;
		font-family: Arial;
		color: #505050;
		line-height:36px;
	}
	.notices-buttonbox{
		height: 50px;
		margin-left: 10%;
		z-index: 99;
		position: absolute;
		bottom: -20px;
	}
	.news-button{
		width: 600px;
		height: 80px;
		background: #FDD000;
		border-radius: 50px !important;
		font-size: 30px;
		border: none;
		color: #505050;
	}
</style>