<template>
	<!-- 特色事件 -->
	<div class="evenas">
		<el-row>
			<el-col :span="24" class="evenas-title">
				Feature Events
			</el-col>
		</el-row>
		<el-row class="chainbox">
			<el-col :span="8" :offset="8">
				<el-image class="event-chainimg" src="/home/chain.png"></el-image>
			</el-col>
		</el-row>
		<!-- 特色事件-数据 -->
		<el-row class="evenas-databox">
			<el-col :span="9" :offset="2" v-for="(item,id) in evenasListData" :key="id" class="evenas-data">
				<div @click="details(item)">
					<el-row class="evenas-data-extend">
						<img :src="eventsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="eventsdata-img"></img>
						<el-image class="evenasdata-coverageimg" src="/home/homeEventMcoverage.png"></el-image>
						<el-image class="evenasdata-logoimg" src="/home/logo.png"></el-image>
						<div class="evenasdata-titleMessage">
							<el-row class="evenasdata-title">{{item.title}}</el-row>
							<el-row v-html="item.bodyContent" class="evenasdata-message">{{item.bodyContent}}</el-row>
						</div>	
						<el-row class="evenasdata-date">
							<span>{{item.time}}</span>
							<span>{{item.month}}</span>
							<span>{{item.year}}</span>
						</el-row>
					</el-row>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { selectContentListById } from '@/api/index.js'
	export default
	{
		data()
		{
			return{
				evenasList:[],
				eventsNotLoadImage: "/Mimage/335_252.jpg", //事件图片未加载出来
			}
		},
		computed:
		{
			// 特色事件
			evenasListData()
			{
				let data=[];
				if(this.evenasList.length>6)
				{
					data=this.evenasList.slice(0,6)
					return data;
				}
				else
				{
					data=this.evenasList;
					return data;
				}
			},
		},
		created()
		{
			this.getEvenasList();
		},
		methods:
		{
			// 获取特色事件数据
			getEvenasList()
			{
				selectContentListById(
				{
				  chnanelType: 2,
				  isIndex: 1,
				  moduleType: 'MODULE_PERFORMANCES'
				})
				.then((response)=>
				{
					this.evenasList=response.data.data;
					console.log(this.evenasList.length,'长度')
					this.evenasList.map((v)=>
					{
						this.time(v)
					})
				})
			},
			// 特色事件 - 加载 -收起
			eveLoad()
			{
				this.$router.push('/moveNews')
			},
			// 详情
			details(item)
			{
				localStorage.setItem('Details',JSON.stringify(item));
				this.$router.push(
				{
					path:'/moveNoticesDetails',
					query:
					{
						item:item,
						headTitle:'WHAT`S ON',
						headImg:'/index/list.png'
					}
				})
			},
			time(v)
			{
				v.pulish=v.publish.split('-')
				if(v.pulish[1]=='01')
				{
					v.pulish[1]='JAN'
				}
				else if(v.pulish[1]=='02')
				{
					v.pulish[1]='FEB'
				}
				else if(v.pulish[1]=='03')
				{
					v.pulish[1]='MAR'
				}
				else if(v.pulish[1]=='04')
				{
					v.pulish[1]='APR'
				}
				else if(v.pulish[1]=='05')
				{
					v.pulish[1]='MAY'
				}
				else if(v.pulish[1]=='06')
				{
					v.pulish[1]='JUN'
				}
				else if(v.pulish[1]=='07')
				{
					v.pulish[1]='JUL'
				}
				else if(v.pulish[1]=='08')
				{
					v.pulish[1]='AUG'
				}
				else if(v.pulish[1]=='09')
				{
					v.pulish[1]='SEP'
				}
				else if(v.pulish[1]=='10')
				{
					v.pulish[1]='OCT'
				}
				else if(v.pulish[1]=='11')
				{
					v.pulish[1]='NOV'
				}
				else if(v.pulish[1]=='12')
				{
					v.pulish[1]='DEC'
				}
				v.year=v.pulish[0];
				v.month=v.pulish[1];
				v.time=v.pulish[2];
			},
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style scoped>
	@import '../../assets/MoveHomeTwoDate.css';
	.evenas{
		width: 100%;
		margin-top: 40px;
	}
	.evenas-title{
		text-align: center;
		height: 36px;
		font-size: 48px;
		font-family: Arial;
		color: #505050;
		line-height:36px;
	}
	.chainbox{
		margin-top: 26px;
		margin-bottom: 27px;
	}
	.event-chainimg{
		width: 128px;
		height: 13px;
	}
	.evenas-databox{
		width: 97%;
		margin-left: 2%;
	}
	.disabled-button{
		width: 100%;
		height: 50px;
		color: #505050;
		line-height: 20px;
		border: none;
		font-size: 18px;
		background-color: #CCC;
	}
</style>